import { useGameContext } from "contexts";
import QRCode from "react-qr-code";

export const KioskThankYou = () => {
  const { endTime } = useGameContext();

  return (
    <div className="relative">
      <div className="text-[180px] text-white text-BellSlim-Heavy leading-tight mt-24">
        {endTime}
      </div>
      <div className="text-white text-BellSlim-Heavy text-[80px] uppercase leading-tight mb-8">
        Well done!
      </div>
      <div className="text-white text-BellSlim-SemiBold text-[70px] leading-tight">
        Speak to a Bell
        <br />
        representative
        <br />
        for a chance to
        <br />
        win a new phone
        <br />
        on Canada’s best
        <br />
        5G network
      </div>

      <div className="absolute top-[50px] -right-[350px]">
        <img src={require("assets/images/ThankYou.png")} alt="Thank You" />
      </div>

      <div className="flex items-center mt-16">
        <div className="p-4 bg-white rounded-xl mr-8">
          <QRCode
            size={240}
            value={process.env.REACT_APP_TY_QR_VALUE}
            viewBox={`0 0 240 240`}
          />
        </div>

        <div className="text-white text-BellSlim-SemiBold text-[70px] leading-tight">
          Or scan for your
          <br />
          chance to win
        </div>
      </div>
    </div>
  );
};
