import { MobileLayout } from "components";
import { useGameContext } from "contexts";

export const MobileThankYou = () => {
  const { endTime } = useGameContext();

  return (
    <MobileLayout>
      <div className="relative">
        <div className="text-[60px] text-white text-BellSlim-Heavy mt-12">
          {endTime}
        </div>
        <div className="text-white text-BellSlim-Heavy text-[30px] uppercase mb-4">
          Well done!
        </div>
        <div className="text-white text-BellSlim-SemiBold text-[24px]">
          Speak to a Bell
          <br />
          representative
          <br />
          for a chance to
          <br />
          win a new phone
          <br />
          on Canada’s best
          <br />
          5G network
        </div>

        <div className="absolute top-[20px] -right-[120px]">
          <img
            className="w-[300px] h-auto"
            src={require("assets/images/ThankYou.png")}
            alt="Thank You"
          />
        </div>
      </div>
    </MobileLayout>
  );
};
