import clsx from "clsx";

export const KioskLayout = ({ className, children }) => {
  return (
    <div
      className={clsx(
        "relative w-full min-h-[120rem] flex flex-col p-24 bg-[#225295] overflow-hidden",
        className
      )}
    >
      <div className="w-full">
        <div className="mb-8">
          <img src={require("assets/images/logo.png")} alt="Logo" />
        </div>

        <div className="text-BellSlim-Heavy text-[#fff] text-[70px] mb-8 leading-tight">
          Mosaic
          <br />
          &nbsp;
        </div>
      </div>

      {children}
    </div>
  );
};
