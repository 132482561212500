import { MobileLayout } from "components";

export const MobileDisconnected = () => {
  return (
    <MobileLayout>
      <div className="text-white text-BellSlim-Heavy text-[30px] uppercase mt-12">
        Disconnected
      </div>

      <div className="text-white text-BellSlim-SemiBold text-[24px]">
        Speak to a Bell
        <br />
        representative
        <br />
        for a chance to
        <br />
        win a new phone
        <br />
        on Canada’s best
        <br />
        5G network
      </div>
    </MobileLayout>
  );
};
