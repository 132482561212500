import clsx from "clsx";

export const MobileLayout = ({
  className,
  children,
  onClick: handleClick = () => null,
}) => (
  <div
    className={clsx(
      "relative w-screen h-screen flex flex-col bg-[#225295] p-4 overflow-hidden",
      className
    )}
    onClick={handleClick}
  >
    <div className="w-full">
      <div className="h-16 mt-16 mb-4">
        <img
          className="h-full w-auto"
          src={require("assets/images/logo.png")}
          alt="Logo"
        />
      </div>

      <div className="text-BellSlim-Heavy text-[#fff] text-[24px] leading-tight">
        Mosaic
        <br />
        &nbsp;
      </div>
    </div>

    {children}
  </div>
);
