export const KioskHowToPlay = () => {
  return (
    <div className="relative pt-40">
      <div className="text-white text-BellSlim-Heavy text-[80px] mb-8">
        HOW TO PLAY
      </div>
      <div className="text-white text-BellSlim-SemiBold text-[70px] mb-8">
        Tap on cards to
        <br />
        reveal them.
      </div>
      <div className="text-white text-BellSlim-SemiBold text-[70px]">
        Match the
        <br />
        pairs to win.
      </div>

      <div className="absolute top-[260px] -right-[350px]">
        <img src={require("assets/images/HowToPlay.png")} alt="How To Play" />
      </div>
    </div>
  );
};
